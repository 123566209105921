import request, { baseUrl } from "@/utils/request";

/*
 *@描述: 获取用户人谱特征信息
 *@参数: id: 订单的id
 *@作者: Joker
 *@日期: 2022-1-10 17:54:40
 */
export function getUserCharacter() {
  return request({
    url: `${baseUrl.api}/user/getUserCharacter`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}
