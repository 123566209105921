<template>
  <van-popup
    class="container-prop"
    v-model="show"
    closeable
    round
    @close="handleClose"
  >
    <h3 class="title">{{ title }}</h3>
    <slot />
  </van-popup>
</template>

<script>
export default {
  name: "AlertProp",
  props: {
    // 弹窗的标题默认（标题）
    title: {
      type: String,
      default: "标题",
    },

    // 是否展示弹窗
    showProp: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showProp(newVal) {
      this.show = newVal;
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    // 监听遮罩关闭
    handleClose() {
      this.$emit("cancel", this.show);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-prop {
  padding: 0.6rem 0.4rem;
  min-width: 85%;

  .title {
    font-size: 0.36rem;
    font-weight: 600;
    color: #6b4e16;
  }
  /deep/ {
    .van-popup__close-icon--top-right {
      top: 0.65rem;
      font-size: 0.4rem;
      font-weight: 600;
      color: #6b4e16;
    }
  }
}
</style>
